import React, { useState, useEffect, useCallback, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, ChevronUp, BookOpen, Phone, X, RefreshCw } from 'lucide-react';
import { RetellWebClient } from "retell-client-js-sdk";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { useNavigate } from 'react-router-dom';
import { Button, Card } from './components/UIComponents';

const SCHOOLJOY_API_URL = 'https://schooljoy-staging.eastus2.cloudapp.azure.com';

const processContent = (text) => {
  const imageRegex = /^!\[.*?\]\((.*?)\)/;
  const match = text.match(imageRegex);
  
  if (match) {
    const imageUrl = match[1];
    const remainingText = text.replace(imageRegex, '').trim();
    return {
      imageUrl,
      text: remainingText
    };
  }
  return {
    imageUrl: null,
    text
  };
};

const ScenarioCard = ({ title, subtitle, description, concepts, onLaunch }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const processedTitle = processContent(title);

  return (
    <Card className="mb-8 transform transition-all duration-300 hover:shadow-lg">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="p-6 bg-white">
          {processedTitle.imageUrl && (
            <img 
              src={processedTitle.imageUrl} 
              alt="" 
              className="w-full h-auto mb-4 rounded-lg object-cover max-h-[200px]" 
            />
          )}
          <h3 className="text-2xl sm:text-3xl font-poppins font-bold mb-4 text-primary-blue">
            {processedTitle.text}
          </h3>

          <h4 className="text-xl font-poppins font-medium text-secondary-blue-1 mb-4">{subtitle}</h4>
          <p className="mb-6 text-text-dark">{description}</p>
          
          <Button 
            onClick={onLaunch} 
            variant="primary" 
            className="w-full sm:w-auto mb-4"
          >
            <BookOpen size={20} className="mr-3" />
            Launch Simulation
          </Button>

          <button
            className="flex items-center text-secondary-blue-1 hover:text-primary-blue transition-colors duration-200 text-sm font-medium"
            onClick={(e) => {
              e.preventDefault();
              setIsExpanded(!isExpanded);
            }}
          >
            {isExpanded ? (
              <>
                <ChevronUp size={20} className="mr-2" />
                Hide Durable Human Skills
              </>
            ) : (
              <>
                <ChevronDown size={20} className="mr-2" />
                View Durable Human Skills
              </>
            )}
          </button>
        </div>

        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="overflow-hidden"
            >
              <div className="p-6 bg-gray-50">
                <h5 className="font-semibold mb-3 text-primary-blue">Durable Human Skills:</h5>
                <ul className="list-disc pl-6 space-y-2">
                  {concepts.map((concept, index) => (
                    <li key={index} className="text-text-dark">{concept}</li>
                  ))}
                </ul>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </Card>
  );
};

const ActiveCall = ({ scenarioTitle, status, onEndCall, subtitle, description }) => {
  const processedTitle = processContent(scenarioTitle);

  return (
    <Card className="p-6 sm:p-8 w-full max-w-2xl bg-white shadow-xl">
      <motion.div 
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        className="flex flex-col"
      >
        {/* Header Section */}
        <div className="text-center mb-8">
          {processedTitle.imageUrl && (
            <motion.img 
              src={processedTitle.imageUrl} 
              alt="" 
              className="w-full h-auto mb-6 rounded-lg object-cover max-h-[250px] shadow-md" 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            />
          )}
          <motion.h2 
            className="text-2xl sm:text-3xl font-poppins font-bold text-primary-blue mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            {processedTitle.text}
          </motion.h2>
          <motion.h3 
            className="text-xl font-poppins font-medium text-secondary-blue-1 mb-3"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            {subtitle}
          </motion.h3>
          <motion.p 
            className="text-text-dark text-lg leading-relaxed max-w-2xl mx-auto"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
          >
            {description}
          </motion.p>
        </div>

        {/* Call Status Section */}
        <motion.div 
          className="bg-gray-50 rounded-lg p-6 mb-8 border border-gray-100"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
        >
          <div className="flex items-center justify-center mb-4">
            <motion.div 
              className="w-16 h-16 bg-secondary-blue-2 rounded-full flex items-center justify-center shadow-md"
              animate={{ 
                scale: [1, 1.05, 1],
                opacity: [1, 0.8, 1]
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            >
              <Phone size={28} className="text-text-light" />
            </motion.div>
          </div>
          <div className="text-center">
            <p className="text-secondary-blue-1 text-sm">
              Your responses will be analyzed after the simulation
            </p>
          </div>
        </motion.div>

        {/* Action Button */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.7 }}
        >
          <Button 
            onClick={onEndCall} 
            variant="danger" 
            className="w-full sm:w-auto mx-auto px-8 py-3 text-lg shadow-md hover:shadow-lg transition-shadow"
          >
            <X size={20} className="mr-3" />
            End Simulation
          </Button>
        </motion.div>
      </motion.div>
    </Card>
  );
};

const TranscriptSection = ({ transcript, onRetry, isLoading }) => {
  return (
    <motion.div 
      className="w-full sm:w-1/2 mb-4 sm:mb-0 sm:pr-2"
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg sm:text-h3 font-poppins font-semibold text-primary-blue">Transcript</h3>
        <button 
          onClick={onRetry} 
          className="text-secondary-blue-1 hover:text-primary-blue focus:outline-none"
          disabled={isLoading}
        >
          <RefreshCw size={24} className={isLoading ? "animate-spin" : ""} />
        </button>
      </div>
      <Card className="h-[50vh] sm:h-[70vh] overflow-y-auto bg-white">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="p-4 sm:p-6"
        >
          {isLoading ? (
            <p className="text-secondary-blue-1 italic">Loading transcript...</p>
          ) : transcript ? (
            transcript.split('\n').map((line, index) => (
              <motion.p 
                key={index} 
                className="mb-3 text-text-dark"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.05, duration: 0.3 }}
              >
                {line}
              </motion.p>
            ))
          ) : (
            <p className="text-secondary-blue-1 italic">Transcript not available. Please try again.</p>
          )}
        </motion.div>
      </Card>
    </motion.div>
  );
};

const AnalysisSection = ({ analysis, onRetry, isLoading }) => {
  const analysisRef = useRef(null);

  useEffect(() => {
    if (analysisRef.current) {
      analysisRef.current.scrollTop = analysisRef.current.scrollHeight;
    }
  }, [analysis]);

  return (
    <motion.div 
      className="w-full sm:w-1/2 sm:pl-2"
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg sm:text-h3 font-poppins font-semibold text-primary-blue">Call Analysis</h3>
        <button 
          onClick={onRetry} 
          className="text-secondary-blue-1 hover:text-primary-blue focus:outline-none"
          disabled={isLoading}
        >
          <RefreshCw size={24} className={isLoading ? "animate-spin" : ""} />
        </button>
      </div>
      <Card className="h-[50vh] sm:h-[70vh] overflow-y-auto bg-white">
        <motion.div 
          ref={analysisRef}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="p-4 sm:p-6"
        >
          {isLoading && analysis.length === 0 ? (
            <p className="text-secondary-blue-1 italic">Generating analysis...</p>
          ) : (
            <ReactMarkdown 
              rehypePlugins={[rehypeRaw]} 
              remarkPlugins={[remarkGfm]}
              className="text-text-dark"
            >
              {analysis || 'Analysis not available. Please try again.'}
            </ReactMarkdown>
          )}
        </motion.div>
      </Card>
    </motion.div>
  );
};

const CallDetails = ({ callId, promptName, onStartNewScenario }) => {
  const [transcript, setTranscript] = useState(null);
  const [analysis, setAnalysis] = useState('');
  const [transcriptLoading, setTranscriptLoading] = useState(true);
  const [analysisLoading, setAnalysisLoading] = useState(true);
  const abortControllerRef = useRef(null);
  const [activeScenario, setActiveScenario] = useState(null);
  const [retryCount, setRetryCount] = useState(0);

  const fetchTranscript = useCallback(async () => {
    setTranscriptLoading(true);
    try {
      const response = await fetch(`${SCHOOLJOY_API_URL}/get_retell_call_details?call_id=${callId}`);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to fetch call details: ${JSON.stringify(errorData)}`);
      }
      const data1 = await response.json();
      const data = JSON.parse(data1);
      console.log('Received call details:', data);
      
      if (!data.transcript && retryCount < 3) {
        // If no transcript and retries remaining, retry after 1 second
        setRetryCount(prev => prev + 1);
        await new Promise(resolve => setTimeout(resolve, 1000));
        return fetchTranscript();
      }
      
      setTranscript(data.transcript);
      setActiveScenario(data.scenario_title);
      return data.transcript; // Return transcript for chaining
    } catch (error) {
      console.error('Error fetching transcript:', error);
      setTranscript(null);
      return null;
    } finally {
      setTranscriptLoading(false);
    }
  }, [callId, retryCount]);

  const fetchAnalysis = useCallback(async (hasTranscript) => {
    // Only proceed if we have a transcript
    if (!hasTranscript) {
      console.log('Skipping analysis fetch - no transcript available');
      return;
    }

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();

    setAnalysisLoading(true);
    setAnalysis('');
    try {
      const response = await fetch(
        `${SCHOOLJOY_API_URL}/generate_call_analysis?call_id=${callId}&prompt_name=${promptName}`,
        { signal: abortControllerRef.current.signal }
      );
      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value);
        setAnalysis(prevAnalysis => prevAnalysis + chunk);
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Error fetching call analysis:', error);
        setAnalysis('Error: Failed to fetch call analysis');
      }
    } finally {
      setAnalysisLoading(false);
    }
  }, [callId, promptName]);

  useEffect(() => {
    if (callId) {
      const timer = setTimeout(async () => {
        const transcript = await fetchTranscript();
        // Only fetch analysis if we have a transcript
        if (transcript) {
          await fetchAnalysis(true);
        }
      }, 2000);

      return () => {
        clearTimeout(timer);
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
      };
    }
  }, [callId, promptName, fetchAnalysis, fetchTranscript]);

  const processedTitle = processContent(activeScenario || '');

  return (
    <Card className="mt-6 sm:mt-10 p-4 sm:p-8 w-full max-w-6xl bg-white">
      <motion.div 
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="mb-6 sm:mb-8 text-center">
          {processedTitle.imageUrl && (
            <img 
              src={processedTitle.imageUrl} 
              alt="" 
              className="w-full h-auto mb-4 rounded-lg object-cover max-h-[200px] mx-auto" 
            />
          )}
          <h2 className="text-xl sm:text-h2 font-poppins font-bold text-primary-blue">
            {processedTitle.text || 'Call Results'}
          </h2>
        </div>
        
        <div className="flex flex-col sm:flex-row sm:space-x-6">
          <TranscriptSection 
            transcript={transcript} 
            onRetry={fetchTranscript}
            isLoading={transcriptLoading}
          />
          <AnalysisSection 
            analysis={analysis} 
            onRetry={fetchAnalysis}
            isLoading={analysisLoading}
          />
        </div>
        
        <motion.div 
          className="mt-6 sm:mt-10 flex justify-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.5 }}
        >
          <Button
            onClick={onStartNewScenario}
            variant="primary"
            className="px-6 py-3 sm:px-8 sm:py-4 text-base sm:text-lg"
          >
            Back to Scenarios
          </Button>
        </motion.div>
      </motion.div>
    </Card>
  );
};

const IntegratedTrainingModule = ({ 
  sector,
  logo,
  title,
  subtitle,
  description,
  scenarios
}) => {
  const [activeScenario, setActiveScenario] = useState(null);
  const [activeSubtitle, setActiveSubtitle] = useState(null);
  const [activeDescription, setActiveDescription] = useState(null);
  const [status, setStatus] = useState('Ready to start the call');
  const [retellWebClient, setRetellWebClient] = useState(null);
  const [callId, setCallId] = useState(null);
  const [promptName, setPromptName] = useState(null);
  const [currentView, setCurrentView] = useState('selection');

  const navigate = useNavigate();

  useEffect(() => {
    const client = new RetellWebClient();
    setRetellWebClient(client);

    const handleCallStarted = () => {
      console.log('Call started');
      setStatus('Recording your responses...');
    };

    const handleCallEnded = () => {
      console.log('Call ended by agent or system');
      setStatus('Call ended');
      setCurrentView('results');
    };

    const handleDisconnect = (data) => {
      console.log('Disconnected from room:', data);
      setStatus('Call disconnected');
      handleCallEnded();
    };

    const handleError = (error) => {
      console.error('An error occurred:', error);
      setStatus(`Error: ${error.message}`);
      setActiveScenario(null);
      setCurrentView('selection');
    };

    client.on('call_started', handleCallStarted);
    client.on('call_ended', handleCallEnded);
    client.on('disconnect', handleDisconnect);
    client.on('error', handleError);

    return () => {
      client.off('call_started', handleCallStarted);
      client.off('call_ended', handleCallEnded);
      client.off('disconnect', handleDisconnect);
      client.off('error', handleError);
    };
  }, []);

  const startCall = async (scenarioTitle, agentId, promptName, subtitle, description) => {
    try {
      setStatus('Initializing simulation...');
  
      const response = await fetch(`${SCHOOLJOY_API_URL}/create-web-call/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          agent_id: agentId,
        }),
      });
  
      if (!response.ok) {
        const errorText = await response.json();
        throw new Error(`Server Error: ${response.status} - ${errorText}`);
      }
  
      const responseText = await response.json();
      console.log('Server response:', responseText);
      const createCallResponse = JSON.parse(responseText);
      console.log('Parsed response:', createCallResponse);
      const { access_token: accessToken, call_id: newCallId } = createCallResponse;
  
      if (!accessToken) {
        throw new Error('No access token received from server.');
      }
  
      await retellWebClient.startCall({ accessToken });
      console.log(`Call started - ID: ${newCallId}`);
      setStatus('Recording your responses...');
      setActiveScenario(scenarioTitle);
      setActiveSubtitle(subtitle);
      setActiveDescription(description);
      setCallId(newCallId);
      setPromptName(promptName);
      setCurrentView('call');
    } catch (error) {
      console.error('Error starting the call:', error);
      setStatus('Failed to start simulation');
    }
  };

  const endCall = () => {
    if (retellWebClient) {
      retellWebClient.stopCall();
      setStatus('Finishing up...');
    }
  };

  const handleStartNewScenario = () => {
    setActiveScenario(null);
    setActiveSubtitle(null);
    setActiveDescription(null);
    setCallId(null);
    setPromptName(null);
    setStatus('Ready to start the call');
    setCurrentView('selection');
    navigate(`/${sector}`);
  };

  return (
    <div className="min-h-screen bg-background-gray text-text-dark flex flex-col font-inter">
      <div className="fixed inset-0 bg-gradient-to-b from-background-gray to-primary-blue opacity-20 -z-10"></div>
      
      <div className="flex-grow flex flex-col items-center justify-start p-4 sm:p-10 overflow-y-auto">
        <motion.img
          src={logo}
          alt={`${sector} Logo`}
          className="w-auto h-auto max-w-[200px] sm:max-w-[300px] max-h-[100px] sm:max-h-[150px] mb-6 sm:mb-10"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut" }}
        />
        
        <AnimatePresence mode="wait">
          {currentView === 'selection' && (
            <motion.div
              key="scenario-selection"
              className="w-full max-w-4xl"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
            >
              <motion.h1
                className="text-2xl sm:text-h1 font-poppins font-bold mb-4 sm:mb-6 text-center leading-tight text-primary-blue"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 1 }}
              >
                {title}
              </motion.h1>
              <motion.h2
                className="text-xl sm:text-h2 font-poppins font-semibold mb-4 sm:mb-6 text-center text-secondary-blue-1"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4, duration: 1 }}
              >
                {subtitle}
              </motion.h2>
              <motion.p
                className="text-body mb-8 sm:mb-14 text-center text-text-dark"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.8, duration: 1 }}
              >
                {description}
              </motion.p>
              
              {scenarios.map((scenario, index) => (
                <ScenarioCard
                  key={index}
                  title={scenario.title}
                  subtitle={scenario.subtitle}
                  description={scenario.description}
                  concepts={scenario.concepts}
                  onLaunch={() => startCall(
                    scenario.title,
                    scenario.agentId,
                    scenario.promptName,
                    scenario.subtitle,
                    scenario.description
                  )}
                />
              ))}
            </motion.div>
          )}
          {currentView === 'call' && (
            <motion.div
              key="active-call"
              className="flex-grow flex items-center justify-center w-full py-8"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
            >
              <ActiveCall
                scenarioTitle={activeScenario}
                subtitle={activeSubtitle}
                description={activeDescription}
                status={status}
                onEndCall={endCall}
              />
            </motion.div>
          )}
          {currentView === 'results' && (
            <motion.div
              key="call-results"
              className="w-full max-w-6xl"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
            >
              <CallDetails
                callId={callId}
                promptName={promptName}
                scenarioTitle={activeScenario}
                onStartNewScenario={handleStartNewScenario}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      
      <motion.div
        className="p-4 sm:p-6 w-full text-center text-small text-text-dark"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1, duration: 1 }}
      >
        This site is built strictly for demo purposes only.
      </motion.div>
    </div>
  );
};

export default IntegratedTrainingModule;
